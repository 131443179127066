import React from 'react';
// import Jumbotron from 'react-bootstrap/Jumbotron';
// import Container from 'react-bootstrap/Container';
import { Container } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.css'

export const Jumbotron = () => (
  <div

  className="page-header section-dark"
  style={{
    backgroundImage:
      "url(" + require("../../assets/img/dishes.jpg") + ")"
  }}
>
  <div className="filter" />
  <div className="content-center">
    <Container>
      <div className="title-brand">
        <h3 className="presentation-title">Restaurant App</h3>



      </div>
      <h2 className="presentation-subtitle text-center">
        Please scroll down to create your order
      </h2>
    </Container>
  </div>
 


</div>
)


// <div
// className="moving-clouds2"
// style={{
//   backgroundImage: "url(" + require("../../assets/img/clouds.png") + ")"
// }}
// />