import React from 'react';

import menu from './json/menu1.json'
import { Wrapper } from './components/Wrapper/Wrapper.js';
import Modal from './components/Modal/Modal';
import Navbar from './components/Navbar';
import { Jumbotron } from './components/Jumbotron';
import Sidebar from './components/Sidebar'
import MenuNavbar from './components/Menu-Navbar/Menu-Navbar1';
import { Alert } from 'reactstrap'
import './App.css'


class App extends React.Component {
  constructor() {
    super();
    this.state = {
      menu,
      isShowing: false,
      selectedItem: {},
      validTime: true
    }
  };
  componentDidMount() {
    const now = new Date().getHours()
    if (now < 10 || now > 21) {
      this.setState({ validTime: false })
    }
  }

  openModalHandler = () => {
    this.setState({
      isShowing: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isShowing: false
    });
  }

  itemHandler = (item) => {
    this.setState({
      selectedItem: item
    })
  }
  modalHandler = () => {
    this.setState({
      isShowing: !this.state.isShowing
    })
  }

  render() {
    return (
      <div style={{ marginBottom: 200 }} id="outer-container">
        <Sidebar validTime={this.state.validTime} />
        <main id="page-wrap">
          <Navbar />
          <Jumbotron />
          <MenuNavbar />
          {
            !this.state.validTime && <div className='not-open-parent'><Alert color="danger" className='not-open'>
              Restaurant is closed. Store hours are from 10am - 10pm. (Sidebar button can be disabled during this time)
      </Alert></div>
          }

          <div className='container'>


            <Modal
              item={this.state.selectedItem}
              className="modal"
              show={this.state.isShowing}
              close={this.closeModalHandler}
              toggle={this.modalHandler}
            >
            </Modal>
            <Wrapper menu={this.state.menu} open={this.openModalHandler} close={this.closeModalHandler} itemHandler={this.itemHandler}></Wrapper>
          </div>

        </main>
      </div>
    )
  }

}

export default App;
