import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { removeItem } from '../../reducer/action';





const CheckOutListItem = (props) => {
  return (
    <ListGroup.Item className="d-flex justify-content-between lh-condensed">
      <div>
        <h6 className="my-0">{props.cartItem.name}</h6>
        {
          props.cartItem.selectedOption.optionName ? <small className="text-muted">{props.cartItem.selectedOption.optionName}{Number(props.cartItem.selectedOption.optionPrice) ? ` + $${props.cartItem.selectedOption.optionPrice}` : null}<br></br></small> : null
        }
        {
          props.cartItem.selectedAddons ? props.cartItem.selectedAddons.map((x, i) => (
            <small key={`asdf${i}`} className="text-muted">{`${x.addonName} +$${x.addonPrice}`}<br></br></small>
          )) : null
        }
        {
          props.cartItem.instructions ? <small className="text-muted">{props.cartItem.instructions}</small> : null
        }
      </div>
      <span className="text-muted">{props.cartItem.quantity !== 1 ? `${props.cartItem.quantity} x ` : null}${props.cartItem.price}</span>
    </ListGroup.Item>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    removeItem: item => dispatch(removeItem(item))
  }
}
export default connect(null, mapDispatchToProps)(CheckOutListItem);


