import React, { useState, useEffect, useRef } from 'react';

import { withRouter } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { sendPaymentIntent, sendOrderAfterPayment} from '../../API/API';
import { connect } from 'react-redux';
import { emptyCart } from '../../reducer/action';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import ReactTooltip from "react-tooltip";
import './CheckOut-Form.scss'

const CheckoutForm = ({ toggleCartReducer, form, subTotal, history, emptyCart }) => {
    const [paymentFormEmpty, setPaymentFormEmpty] = useState(true)
    const [loader, setLoader] = useState(false)
    const [updateState, setUpdateState] = useState(false)
    const [cardFieldsEmpty, setCardFieldsEmpty] = useState(true)
    const [cartNotEmpty, setCartNotEmpty] = useState(false)
    useEffect(()=>{
      toggleCartReducer.cartItems.length ? setCartNotEmpty(true) : setCartNotEmpty(false)
    }, [toggleCartReducer])
    useEffect(() => {
        form.contact && form.contact.values && (form.contact.values.firstName && form.contact.values.lastName && form.contact.values.email && form.contact.values.phoneNumber && form.contact.values.address && form.contact.values.state && form.contact.values.city && form.contact.values.zip) ? setPaymentFormEmpty(false) : setPaymentFormEmpty(true)
    }, [form.contact])
    const stripe = useStripe();
    const elements = useElements();
    const element = elements && elements.getElement(CardElement)
    useEffect(()=>{
      console.log(toggleCartReducer)
    })
    useEffect(()=>{
      if (element) {
        console.log('ELEMENT IS DEFINED')
        element._empty ? setCardFieldsEmpty(true) : setCardFieldsEmpty(false)
      }
    }, [updateState, element])
    const handleSubmit = async () => {
        setLoader(true)
        let order = {};
        order.user = form.contact.values;
        order.food = toggleCartReducer.cartItems
        order.subTotal = Number(subTotal)
        order.tax = Number((subTotal * .0725).toFixed(2))
        order.totalPrice = Number(subTotal + order.tax).toFixed(2)
        order.time = order.food.reduce((accum, currentItem, currentIndex) => {
            if ((currentIndex + 1) % 2 === 0) {
                return accum + 5
            }
            return accum
        }, 10)
        sendPaymentIntent(order).then(async (r)=>{
            if (r.data.errorMessage){
                history.push('/uhoh')
            }
            const result = await stripe.confirmCardPayment(r.data.body.client_secret, {
                payment_method: {
                  card: element,
                  billing_details: {
                    name: form.contact.values.firstName + ' ' + form.contact.values.lastName,
                    phone: form.contact.values.phoneNumber,
                    email: form.contact.values.email
                  },
                }
              });

              if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                history.push('/uhoh')
              } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                    sendOrderAfterPayment(order).then(r=>{
                      history.push('/thankyou')
                    })

                  // Show a success message to your customer
                  // There's a risk of the customer closing the window before callback
                  // execution. Set up a webhook or plugin to listen for the
                  // payment_intent.succeeded event that handles any business critical
                  // post-payment actions.
                }
              }
        })
    };



    return (
        <div className="checkout">
              <div>
              <CardElement onChange={()=>setUpdateState(!updateState)}/>
              <div className='pay-button-container tooltip-wrapper disabled' data-tip="Please input card details!" >
            <Button className='btn-round' color='danger' onClick={handleSubmit} disabled={!stripe || !elements || cardFieldsEmpty || !cartNotEmpty || paymentFormEmpty}>Confirm Payment</Button>
            </div>
{           cardFieldsEmpty && <ReactTooltip effect='solid' className='tooltip-test' arrowColor='#FFFFFF' borderColor='#E3E3E3' border={true} backgroundColor='#FFFFFF' textColor='#66615B'/>}
              </div>
            {
              loader ? <div style={{ textAlign: 'center' }}>
              <Spinner style={{ width: '3rem', height: '3rem', color: '#f5593d', }} />{' '}
            </div> : null
            }
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        ...state,
        subTotal: Number(state.toggleCartReducer.cartItems.reduce((totalPrice, item) => totalPrice + item.selectedAddons.reduce((total, addon) => total + Number(addon.addonPrice), 0) + Number(item.selectedOption.optionPrice) + item.quantity * item.price, 0).toFixed(2)),

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        emptyCart: () => dispatch(emptyCart())
    }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutForm));
