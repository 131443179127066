import Menu from '../Menu/Menu'
import React from 'react';
import { connect } from 'react-redux';
import './Sidebar.scss'
import CartItem from '../Cart-Item/Cart-Item'

import { withRouter } from 'react-router-dom';
import { action as toggleMenu } from 'redux-burger-menu';
import { Button } from 'reactstrap';

const Sidebar = (props) => {
  return (

    <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"} customBurgerIcon={false}>

      {
        props.state.toggleCartReducer.cartItems.length ?
          props.state.toggleCartReducer.cartItems.map((cartItem, index) => (
            <CartItem key={`erm${index}`} item={cartItem} cart='cart' />
          )) : <span className='empty-message'>Your cart is empty</span>
      }
      {
        props.location.pathname === '/checkout' ? <Button className="btn-round customb"
          color="danger" onClick={() => {
            props.history.push('/');
            props.state.burgerMenu.isOpen ? props.toggleMenu(false) : props.toggleMenu(true)
          }}>Back to Menu Items</Button> : props.state.toggleCartReducer.cartItems.length ? <Button className="btn-round customb"
            color="danger" onClick={() => {
              props.history.push('/checkout');
              props.state.burgerMenu.isOpen ? props.toggleMenu(false) : props.toggleMenu(true)
            }}>GO TO CHECKOUT</Button> : null
      }
    </Menu>

  )
}

const mapStateToProps = (state) => {
  return { state }
}

const mapDispatchToProps = (dispatch) => ({
  toggleMenu: boolean => dispatch(toggleMenu(boolean))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));

