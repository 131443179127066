import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import './OrderMethod.css'

import DeliveryForm from '../Delivery-Form/Delivery-Form'
import ContactForm from '../UserContactForm/UserContactForm';
import PaymentMethods from '../PaymentMethods/PaymentMethods'


const OrderMethod = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [paymentTab, setPaymentTab] = useState('1');
  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  const [active3, setActive3] = useState(false)
  const [active4, setActive4] = useState(false)


  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div className='orderMethod'>
      <Nav tabs>
        <NavItem className='nav-hover'>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Pick Up
          </NavLink>
        </NavItem>
        <NavItem className='nav-hover'>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Delivery
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col>
              <ContactForm
                paymentTab={paymentTab}
                active1={active1}
                active2={active2}
                active3={active3}
                active4={active4}

              />
              <PaymentMethods
                setActive1={setActive1}
                setActive2={setActive2}
                setActive3={setActive3}
                setActive4={setActive4}

                paymentTab={paymentTab}
                setPaymentTab={setPaymentTab}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>
              <DeliveryForm />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default OrderMethod;


