import { createStore, combineReducers } from 'redux';
import { reducer as burgerMenu } from 'redux-burger-menu';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import toggleCartReducer from '../reducer/toggleCart.reducer'
import { persistStore } from 'redux-persist';
import { reducer as formReducer } from 'redux-form';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['toggleCartReducer']
}

const reducers = {
  toggleCartReducer,
  burgerMenu, // Must be mounted at 'burgerMenu'
  form: formReducer
};
const reducer = combineReducers(reducers);

const persist = persistReducer(persistConfig, reducer)

export const store = createStore(persist)

export const persistor = persistStore(store)

export default { store, persistor };

