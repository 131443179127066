
import { ADD } from './action';
import { REMOVE } from './action';
import { addItemToCart } from './cart.utils';
import { EMPTY_CART } from './action';


const INITIAL_STATE = {
    hidden: true,
    cartItems: []

};

const toggleCartReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADD:
            return {
                ...state,
                cartItems: addItemToCart(state.cartItems, action.payload)
            }
        case REMOVE:
            return {
                ...state,
                cartItems: state.cartItems.filter((item) => JSON.stringify(item) !== JSON.stringify(action.payload))
            }
        case EMPTY_CART:
            return {
                ...state,
                cartItems: []
            }

        default:
            return state;
    }
}

export default toggleCartReducer;