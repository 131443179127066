import React from 'react';
import { Alert } from 'reactstrap';
import './Delivery-Form.scss'
const DeliveryForm = () => {
    return (
      <div className='delivery-form'>
        <Alert color="danger">
        Deliveries to locations within the radius of the restaurant can be implemented as well. 
      </Alert>
      </div>
    )
}

export default DeliveryForm;