import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from './store/index.js';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import CheckOut from './pages/checkout/checkout.js';
import "./assets/scss/paper-kit.scss";
import 'bootstrap/dist/js/bootstrap.js';
import ThankYouPage from './pages/ThankYouPage/ThankYouPage1';
import ScrollToTop from './pages/ScrollToTop/ScrollToTop';
import OrderFailedPage from './pages/OrderFailedPage/OrderFailedPage';


ReactDOM.render(
    <Provider store={store}>
    <Router>
    <PersistGate persistor={persistor}>
      <Switch/>
    <Route exact path="/" component={App} />
    
      <ScrollToTop>
      <Route exact path="/checkout" component={CheckOut} />
      <Route exact path='/thankyou' component={ThankYouPage} />
      <Route exact path='/uhoh' component={OrderFailedPage} />
      </ScrollToTop>
      <Redirect to="/" />
    <Switch/>
    </PersistGate>
    </Router>
    </Provider>
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Provider
