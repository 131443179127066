import React from 'react';
import './checkout.css';
import { connect } from 'react-redux'
import Navbar from '../../components/Navbar'
import CheckOutListItem from '../../components/CheckOut-ListItem/CheckOut-ListItem';
import CheckOutHeader from '../../components/CheckOut-Header/CheckOut-Header'



import OrderMethod from '../../components/OrderMethod/OrderMethod'

import Sidebar from '../../components/Sidebar'

const Checkout = (props) => {

    return (
        <div style={{ marginBottom: 200 }} id='outer-container'>
            <Sidebar />
            <main id='page-wrap'>
                <Navbar />
                <CheckOutHeader />
                <div className='container'>
                    <div className="py-5 text-center">

                        <h2 style={{ color: "#f5593d" }}>Checkout form</h2>
                        <p className="lead">Please use the form below to complete your order. Contact information is to be used for order issues only and will not be used/distributed for marketing.</p>
                    </div>
                    <div className='row'>

                        <div className="col-md-4 order-md-2 md-4">
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted">Your cart</span>
                                <span className="badge badge-secondary badge-pill">{props.itemCount}</span>
                            </h4>
                            <ul className="list-group mb-3">
                                {
                                    props.toggleCartReducer.cartItems.map((cartItem, index) => (

                                        <CheckOutListItem key={`z${index}`} cartItem={cartItem} />

                                    ))
                                }
                                <li className="list-group-item d-flex justify-content-between">
                                    <span>Subtotal</span>
                                    <strong>${props.subTotal}</strong>
                                </li>
                                <li className="list-group-item d-flex justify-content-between">
                                    <span>Tax</span>
                                    <strong>${Number((props.subTotal * .0725).toFixed(2))}</strong>
                                </li>
                                <li className="list-group-item d-flex justify-content-between">
                                    <span>Total</span>
                                    <strong>${Number((props.subTotal + (props.subTotal * .0725)).toFixed(2))}</strong>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-8 order-md-1">
                            <OrderMethod />


                        </div>


                    </div>
                </div>
            </main>
        </div>
    )
}

const mapStateToProps = (state) => {
    const itemCount = state.toggleCartReducer.cartItems.reduce((accumulatedQuantity, cartItem) => accumulatedQuantity + cartItem.quantity, 0)
    return {
        ...state,
        itemCount,
        subTotal: Number(state.toggleCartReducer.cartItems.reduce((totalPrice, item) => totalPrice + item.selectedAddons.reduce((total, addon) => total + Number(addon.addonPrice), 0) + Number(item.selectedOption.optionPrice) + item.quantity * item.price, 0).toFixed(2))
    }
}

export default connect(mapStateToProps)(Checkout);

