import React, { useState, useEffect, useRef } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Spinner, Tooltip } from 'reactstrap';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendOrder } from '../../API/API';
import CheckoutForm from '../CheckOut-Form/CheckOut-Form';
import { emptyCart } from '../../reducer/action'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';





const PaymentMethod = ({ toggleCartReducer, form, setActive1, setActive2, setActive3, setActive4, subTotal, history, emptyCart, paymentTab, setPaymentTab }) => {

  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const itemsRef = useRef()
  const [ready, setReady] = useState(false)
  const [cartEmpty, setCartEmpty] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [formEmpty, setFormEmpty] = useState(true)
  const toolTipToggle = () => setTooltipOpen(!tooltipOpen);
  const today = new Date()
  useEffect(() => {
    if (itemsRef.current) {
      setReady(true)
    }
  }, [])
  useEffect(() => {
    if (!toggleCartReducer.cartItems.length) {
      setCartEmpty(true)
    }
  }, [toggleCartReducer.cartItems])

  useEffect(() => {
    form.contact && form.contact.values && (form.contact.values.firstName && form.contact.values.lastName && form.contact.values.email && form.contact.values.phoneNumber) ? setFormEmpty(false) : setFormEmpty(true)
  }, [form.contact])


  useEffect(() => {
    if (form.contact && form.contact.values && form.contact.values.firstName) {
      setActive1(true)
    }
    if (form.contact && form.contact.values && form.contact.values.lastName) {
      setActive2(true)
    }
    if (form.contact && form.contact.values && form.contact.values.email) {
      setActive3(true)
    }
    if (form.contact && form.contact.values && form.contact.values.phoneNumber) {
      setActive4(true)
    }

  }, [form.contact, setActive1, setActive2, setActive3, setActive4])

  const toggle = tab => {
    if (paymentTab !== tab) setPaymentTab(tab);
  }

  const stripePromise = loadStripe("pk_live_RFfQP44iYj67lDR5idUq9WcM002TLg2KTG")


  return (
    <div className='orderMethod'>

      <Nav tabs>
        <NavItem className='nav-hover'>
          <NavLink
            className={classnames({ active: paymentTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Pay in Store
          </NavLink>
        </NavItem>
        <NavItem className='nav-hover'>
          <NavLink
            className={classnames({ active: paymentTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Pay Online
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={paymentTab}>
        <TabPane tabId="1">
          <Row>
            <Col>

              {
                isPlacingOrder ? <div style={{ textAlign: 'center' }}>
                  <Spinner style={{ width: '3rem', height: '3rem', color: '#f5593d', }} />{' '}
                </div> : <div ref={itemsRef} className="tooltip-wrapper disabled" ><Button className="btn-round"
                  color="danger" type="submit" disabled={form.contact && form.contact.values && (form.contact.values.firstName && form.contact.values.lastName && form.contact.values.email && form.contact.values.phoneNumber) && !cartEmpty ? false : true} onClick={() => {
                    setIsPlacingOrder(true);
                    let order = {};
                    order.user = form.contact.values;
                    order.food = toggleCartReducer.cartItems
                    order.subTotal = Number(subTotal)
                    order.tax = Number((subTotal * .0725).toFixed(2))
                    order.totalPrice = Number(order.subTotal) + Number(order.tax)
                    order.time = order.food.reduce((accum, currentItem, currentIndex) => {
                      if ((currentIndex + 1) % 2 === 0) {
                        return accum + 5
                      }
                      return accum
                    }, 10)
                    order.date = {
                      date: `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`,
                      seconds: Date.now(),
                      time: `${today.getHours()}-${today.getMinutes()}`
                    }
                    sendOrder(order, history, emptyCart)
                  }}>Confirm Order </Button>
                    {cartEmpty && ready && <Tooltip style={{ width: '15rem' }} placement="top" isOpen={tooltipOpen} target={itemsRef.current} toggle={toolTipToggle}>
                      Please add menu items to cart!
                </Tooltip>}
                    {formEmpty && ready && <Tooltip style={{ width: '15rem' }} placement="top" isOpen={tooltipOpen} target={itemsRef.current} toggle={toolTipToggle}>
                      Please fill out required form
                </Tooltip>}
                  </div>
              }
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>

              <div className="example">
                <h4 style={{ marginBottom: 20, color: '#f5593d' }}>Please input payment details</h4>
                <Elements stripe={stripePromise}>{/* Your checkout form */}
                  <CheckoutForm />
                </Elements>
              </div>

            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
    subTotal: Number(state.toggleCartReducer.cartItems.reduce((totalPrice, item) => totalPrice + item.selectedAddons.reduce((total, addon) => total + Number(addon.addonPrice), 0) + Number(item.selectedOption.optionPrice) + item.quantity * item.price, 0).toFixed(2)),

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    emptyCart: () => dispatch(emptyCart())
  }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentMethod));


