
import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { Col, Row, Form, FormGroup, Label } from 'reactstrap';
import './UserContactForm.scss'

const UserContactForm = props => {

    return (
        <Form>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label htmlFor="firstName">First Name *</Label>
                        <Field className={props.active1 ? 'form-control' : 'form-control formrequire'} type="text" name="firstName" component="input" />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label htmlFor="lastName">Last Name *</Label>
                        <Field className={props.active2 ? 'form-control' : 'form-control formrequire'} type="text" name="lastName" component="input" />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label htmlFor="email">Email *</Label>
                <Field className={props.active3 ? 'form-control' : 'form-control formrequire'} type="text" name="email" component="input" />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="phoneNumber">Phone Number *</Label>
                <Field className={props.active4 ? 'form-control' : 'form-control formrequire'} type="text" name="phoneNumber" component="input" />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="Address">Address 2</Label>
                <Field className='form-control' type="text" name="address" component="input" />
            </FormGroup>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label htmlFor="City">City</Label>
                        <Field className='form-control' type="text" name="city" component="input" />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label htmlFor="State">State</Label>
                        <Field className='form-control'  type="text" name="state" component="input" />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label htmlFor="Zip">Zip</Label>
                        <Field className='form-control'  type="text" name="zip" component="input" />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}

export default reduxForm({ form: 'contact' })(UserContactForm)
// ContactForm = reduxForm({
//     // a unique name for the form
//     form: 'contact'
//   })(ContactForm)

// export default ContactForm;