// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from "./myform.js"'.
// Finally, add a <MyForm/> element whereever you wish to display the form.

import React from "react";

import Navbar from '../../components/Navbar/index'
import ThankYouHeader from '../../components/ThankYouHeader/ThankYouHeader'
import {
    Button,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Alert
  } from "reactstrap";
  import Sidebar from '../../components/Sidebar'

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
      isFormSent: false
    };
  }
  componentDidMount() {
    this.setState({isFormSent: false})
  }
  formHandler =() => {
    this.setState({
      isFormSent:!this.state.isFormSent
    })
  }
  render() {
    return (
        <div id='outer-container'>
        <Sidebar />
        <main id='page-wrap'>
        <Navbar/>
        <ThankYouHeader />
        <Container>
            <div className="py-5 text-left">
                <h2>Order Accepted!</h2>
                <br></br>
                <br></br>
                <p className="lead">Thank you for choosing Restaurant App. We appreciate your business. A confirmation will be sent to your email. Please contact us at 949-631-8031 with any questions or concerns, or use the email form below. Thank you. 
                </p>
                <br></br>
                <p>Team RestaurantApp</p>
            </div>
            <div className="section">
              <Row>

              {
                this.state.isFormSent ? (<div>
                  <Alert style={{textAlign:'center'}} color="danger">
                  Message Sent! Thank you for your feedback.
                </Alert>
                <Button className='btn-round danger' color='danger' onClick={this.formHandler}>Send another message</Button>
                  </div>) : (
                    <Col className="ml-auto mr-auto" md="8">
                    <h2 className="text-center">Keep in touch?</h2>
                    <Form className="contact-form" onSubmit={
                      (e)=>{
                        this.formHandler()
                        this.submitForm(e);
                      }
                    }
                    action="https://formspree.io/xkngnrzv"
                    method="POST"
                    >
                      <Row>
                        <Col md="6">
                          <label>Name</label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Name" type="text" />
                          </InputGroup>
                        </Col>
                        <Col md="6">
                          <label>Email</label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-email-85" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Email" type="text"  name="email"/>
                          </InputGroup>
                        </Col>
                      </Row>
                      <label>Message</label>
                      <Input
                        placeholder="Tell us your thoughts and feelings..."
                        type="textarea"
                        rows="4"
                        name="message"
                      />
                      <Row>
                        <Col className="ml-auto mr-auto" md="4">
                          <Button className="btn-fill" color="danger" size="lg">
                            Send Message
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  )
              }
              </Row>
          </div>
        </Container>
        </main>
        </div>
    );
  }
  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}

