import axios from "axios";

export const sendOrder = (item, props, emptyCart) => {
    axios.put('https://ki2x8pkdcl.execute-api.us-west-2.amazonaws.com/prod', { "data": JSON.stringify(item) }).then((response) => {
        if (response.data.err || response.data.errorType) {
            props.push('/uhoh')
        } else {
            emptyCart()
            props.push('/thankyou');

        }
    }).catch((e) => {
        props.push('/uhoh')
    });
}

export const sendPaymentIntent = async (item) => {
    const res = axios.post('https://cors-anywhere.herokuapp.com/https://ki2x8pkdcl.execute-api.us-west-2.amazonaws.com/prod', { "data": JSON.stringify(item) })
    return res
}

export const sendOrderAfterPayment = async (item) => {
    const res = axios.put('https://ki2x8pkdcl.execute-api.us-west-2.amazonaws.com/prod', { "data": JSON.stringify(item) })
    return res
}








//https://cors-anywhere.herokuapp.com/