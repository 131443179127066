export const addItemToCart = (cartItems, cartItemToAdd) => {
    const existingCartItem = cartItems.find(cartItem => cartItem.name === cartItemToAdd.name && JSON.stringify(cartItem.selectedAddons) === JSON.stringify(cartItemToAdd.selectedAddons) && JSON.stringify(cartItem.selectedOption) === JSON.stringify(cartItemToAdd.selectedOption));
    if (existingCartItem) {
        return cartItems.map(cartItem =>
            cartItem.name === cartItemToAdd.name && JSON.stringify(cartItem.selectedAddons) === JSON.stringify(cartItemToAdd.selectedAddons) && JSON.stringify(cartItem.selectedOption) === JSON.stringify(cartItemToAdd.selectedOption) ?
                {
                    ...cartItem,
                    quantity: cartItem.quantity + 1
                } : cartItem
        )
    }
    return [...cartItems, { ...cartItemToAdd, quantity: 1 }]
}

