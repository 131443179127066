import React from 'react';
import { connect } from 'react-redux';
import { action as toggleMenu } from 'redux-burger-menu';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import {
  Button,
  Navbar,
  Container
} from "reactstrap";




const IndexNavbar = (props) => {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        //BOTTOM
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        // TOP
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate nav-mobile-spread">
          <Link to="/" className='navbar-brand'>RESTAURANT HOME PAGE</Link>

        </div>

        <div className="navbar-translate nav-mobile-spread">
          <Button
            className="btn-round nav-mobile-spread"
            color="danger"

            onClick={() => {
              props.burgerMenu.isOpen ? props.toggleMenu(false) : props.toggleMenu(true)
            }}
          >
            <span className='item-count'>{props.itemCount}  -  </span>
            Items in Cart
            </Button>
        </div>

      </Container>
    </Navbar>
  );
}

const mapStateToProps = (state) => {
  return { ...state, itemCount: state.toggleCartReducer.cartItems.reduce((accumulatedQuantity, cartItem) => accumulatedQuantity + cartItem.quantity, 0) }
}
const mapDispatchToProps = (dispatch) => ({
  toggleMenu: (boolean) => dispatch(toggleMenu(boolean))
})


export default connect(mapStateToProps, mapDispatchToProps)(IndexNavbar)


