/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import './Card.styles.css'
// import {Card, CardTitle, CardText} from 'reactstrap';



export const Card1= props =>{ 
  return (
  
  <div className='card cardstyle' 
  data-toggle="modal"
  data-target="#exampleModalCenter"
  onClick={()=>{
    const item = {
      name: props.card.name,
      desc: props.card.desc,
      price: props.card.price,
      options: props.card.options,
      addons: props.card.addons
    }
    props.itemHandler(item);
    // document.getElementById("exampleFormControlTextarea1").value = '';
    props.open();
    }}>
    
    <div className='card-body'>
        <h5 className='card-title card-desc'>{props.card.name}</h5>
        <p className='card-text card-desc'>{props.card.desc}</p>
        <p className='card-text'>${props.card.price}</p>

    </div>
  </div>
)}



// <Card body className='cardstyle'   
// data-toggle="modal"
// data-target="#exampleModalCenter"
// onClick={()=>{
//   const item = {
//     name: props.card.name,
//     desc: props.card.desc,
//     price: props.card.price,
//   }
//   props.itemHandler(item);
//   document.getElementById("exampleFormControlTextarea1").value = '';
//   props.open();}}>
// <CardTitle>{props.card.name}</CardTitle>
// <CardText className='card-desc'>{props.card.desc}</CardText>
// <CardText>${props.card.price}</CardText>
// </Card>






// with image =>

// <div className='card cardstyle' 
// data-toggle="modal"
// data-target="#exampleModalCenter"
// onClick={()=>{
//   const item = {
//     name: props.card.name,
//     desc: props.card.desc,
//     price: props.card.price,
//   }
//   props.itemHandler(item);
//   document.getElementById("exampleFormControlTextarea1").value = '';
//   props.open();}}>
//   <div className='row'>
//   <div className="col-sm-3">
//   <img className="card-img img-circle img-no-padding img-responsive" src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="Card image"/>
// </div>
// <div className="col-sm-9">

// <div className='card-body-right'>
// <h5 className='card-title card-desc'>{props.card.name}</h5>
// <p className='card-text card-desc'>{props.card.desc || 'no desc'}</p>
// <p className='card-text'>${props.card.price}</p>
// </div>
// </div> 
//   </div>
// </div>