
import React from "react";
import classnames from "classnames";
import Scroll from 'react-scroll';

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";
import './Menu-Navbar.scss'

const MenuNavbar = () => {
  const scroller = Scroll.scroller;
  // const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  // React.useEffect(() => {
  //   const updateNavbarColor = () => {
  //     if (
  //       document.documentElement.scrollTop > 799 ||
  //       document.body.scrollTop > 799
  //     ) {
  //       setNavbarColor("sticky fixed-top");
  //     } else if (
  //       document.documentElement.scrollTop < 800 ||
  //       document.body.scrollTop < 800
  //     ) {
  //       setNavbarColor("");
  //     }
  //   };

  //   window.addEventListener("scroll", updateNavbarColor);

  //   return function cleanup() {
  //     window.removeEventListener("scroll", updateNavbarColor);
  //   };
  // });


  return (
    <div className='menu-nav-sticky'>
      {navbarCollapse ? (
        <div
          id="bodyClick"
          onClick={() => {
            toggleNavbarCollapse()
          }}
        />
      ) : null}
      <div>
        <div>
          <Navbar
            // className={classnames( navbarColor)}

            color-on-scroll="300"
            expand="lg"
          >
            <Container>
              <div className="navbar-translate">
                {
                  window.innerWidth < 1000 ? <NavbarBrand className='nav-hover' onClick={(e) => { e.preventDefault() }}>
                    Menu Categories
          </NavbarBrand> : null
                }

                <button
                  aria-expanded={navbarCollapse}
                  className={classnames("navbar-toggler navbar-toggler", {
                    toggled: navbarCollapse
                  })}
                  onClick={() => {
                    toggleNavbarCollapse();
                  }}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <Collapse
                className="justify-content-end"
                navbar
                isOpen={navbarCollapse}
              >
                <Nav navbar>
                  <NavItem>
                    <NavLink className='nav-hover' onClick={e => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo1', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -165, // Scrolls to element + 50 pixels down the page
                      });
                      // toggleNavbarCollapse();
                    }}>
                       Signature Cut Rolls
          </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className='nav-hover' onClick={e => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo2', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -165, // Scrolls to element + 50 pixels down the page
                      });
                      //   toggleNavbarCollapse();
                    }}>
                      Standard Cut Rolls
        </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className='nav-hover' onClick={e => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo3', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -165, // Scrolls to element + 50 pixels down the page
                      })
                      // toggleNavbarCollapse();
                    }}>
                      Sushi / Sashimi
      </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className='nav-hover' onClick={e => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo4', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -165, // Scrolls to element + 50 pixels down the page
                      });
                      // toggleNavbarCollapse();
                    }}>
                      Appetizers
              </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className='nav-hover' onClick={(e) => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo5', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -165, // Scrolls to element + 50 pixels down the page
                      });
                      // toggleNavbarCollapse();
                    }}>
                      Soup
              </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink className='nav-hover' onClick={(e) => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo6', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -165, // Scrolls to element + 50 pixels down the page
                      });
                      // toggleNavbarCollapse();
                    }}>
                      Salad
              </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink className='nav-hover' onClick={(e) => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo7', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -165, // Scrolls to element + 50 pixels down the page
                      });
                      // toggleNavbarCollapse();
                    }}>
                      Poultry
          </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className='nav-hover' onClick={(e) => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo8', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -165, // Scrolls to element + 50 pixels down the page
                      })
                      //   toggleNavbarCollapse();
                    }}>
                      Meats
        </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className='nav-hover' onClick={(e) => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo9', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -165, // Scrolls to element + 50 pixels down the page
                      });
                      // toggleNavbarCollapse();
                    }}>
                      Seafood
      </NavLink>

                  </NavItem>
                  <NavItem>
                    <NavLink className='nav-hover' onClick={(e) => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo10', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -165, // Scrolls to element + 50 pixels down the page
                      });
                      //   toggleNavbarCollapse();
                    }}>
                      Vegetables
    </NavLink>

                  </NavItem>


                  <NavItem>
                    <NavLink className='nav-hover' onClick={(e) => {
                      e.preventDefault();
                      scroller.scrollTo('myScrollTo11', {
                        duration: 1000,
                        delay: 100,
                        smooth: true,
                        spy: true,
                        offset: -170, // Scrolls to element + 50 pixels down the page
                      });
                      //   toggleNavbarCollapse();
                    }}>
                      Pasta & Rice
    </NavLink>

                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </div>
  );
}

export default MenuNavbar;
