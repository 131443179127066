export const ADD = 'ADD';
export const REMOVE = 'REMOVE';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const EMPTY_CART = 'EMPTY_CART';





export const addItem = (item) => ({
    type: ADD,
    payload: item
})

export const removeItem = item => ({
    type: REMOVE,
    payload: item
})

export const toggleSidebar = () => {
    return {
        type: TOGGLE_SIDEBAR
    }
}

export const emptyCart = () => {
    return {
        type: EMPTY_CART
    }
}

