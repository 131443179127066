import React from 'react';
import Navbar from '../../components/Navbar/index';
import ThankYouHeader from '../../components/ThankYouHeader/ThankYouHeader';
import {
    Alert,
    Container
  } from "reactstrap";
import Sidebar from '../../components/Sidebar';

  
const ThankYouPage = () => {
    return (
        <div id='outer-container'>
        <Sidebar/>
        <main id='page-wrap'>
            <Navbar/>
            <ThankYouHeader />
            <Container>
                <div className="py-5 text-left">
                <Alert style={{textAlign:'center'}} color="danger">
                Something went wrong! Order not received.
              </Alert>
                    <br></br>
                    <p className="lead">Apologies. Your order was not received. A log of this error been created and sent to our team. Please try again in a few minutes or call the restaurant at 949-631-8031 to complete your order. Thank you
                    </p>
                    <br></br>
                    <p>Team RestaurantApp</p>
                </div>

            </Container>
            </main>
            </div>
    )
}

export default ThankYouPage;