import React from 'react';
import Scroll from 'react-scroll'
import { Cardlist } from '../Card-List/Card-List.js';


export const Wrapper = props => (
  <div>

    {props.menu.menu.map((category, index) => {
      return (
        <div key={`a${index}`} id={`#section${index}`} className={`padding${index}`}>
          <Scroll.Element name={`myScrollTo${index + 1}`}></Scroll.Element>
          <div>
            <h3 style={{ paddingBottom: "10px" }}>
              <span className="note">{category.group}</span>

            </h3>
          </div>
          <Cardlist key={category.group} category={category} open={props.open} close={props.close} itemHandler={props.itemHandler} />
        </div>
      )
    })}

  </div>
);