import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addItem } from '../../reducer/action';
import { ModalHeader, Modal, ModalBody, ModalFooter } from 'reactstrap';
import './Modal.scss'
import UsesToolTip from './UsesToolTip'

const Modal2 = (props) => {
    const [selectedOption, setSelectedOption] = useState({ optionName: null, optionPrice: 0 })
    const [selectedAddons, setSelectedAddons] = useState([])
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle1 = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
        if (Array.isArray(props.item.options) && props.item.options.length && !selectedOption.optionName) {
            return setButtonDisabled(true)
        }
        setButtonDisabled(false)
    }, [selectedOption, props.show, props.item.options])

    useEffect(() => {
        if (props.show === false) {
            setSelectedOption({ optionName: null, optionPrice: 0 })
            setSelectedAddons([])
        }
    }, [props.show])
    // const closeBtn = <button className="close" onClick={props.toggle}>&times;</button>;

    return (
        <div>
            <Modal isOpen={props.show} toggle={props.toggle}>
                <ModalHeader toggle={props.toggle}></ModalHeader>
                <div className="modal-header">
                    <h5 className="modal-title food-modal">{props.item.name}</h5>
                    <div>{props.item.desc} </div>
                </div>
                <ModalBody>
                    {
                        props.item.options ?
                            props.item.options.map((x, i) => {
                                if (i === 0) {
                                    return (
                                        <div key={`asdf{i}`}>
                                            <div className='required-option req-option'>
                                                <div>Please choose a required option</div>
                                                <div className='required-option__italics'>Required</div>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline" key={`blah${i}`}>
                                                <input type="radio" id={`rd_${i + 1}`} name="rd" className="custom-control-input" value={JSON.stringify(x)} onChange={
                                                    (e) => { setSelectedOption(JSON.parse(e.target.value)) }
                                                }></input>
                                                <label className="custom-control-label red" htmlFor={`rd_${i + 1}`}>{x.optionName} {Number(x.optionPrice) ? `+ $${x.optionPrice}` : null}</label>
                                            </div>
                                        </div>
                                    )
                                } else return (
                                    <div className="custom-control custom-radio custom-control-inline" key={`blah${i}`}>
                                        <input type="radio" id={`rd_${i + 1}`} name="rd" className="custom-control-input" value={JSON.stringify(x)} onChange={
                                            (e) => { setSelectedOption(JSON.parse(e.target.value)) }
                                        }></input>
                                        <label className="custom-control-label red" htmlFor={`rd_${i + 1}`}>{(x.optionName)} {Number(x.optionPrice) ? `+ $${x.optionPrice}` : null}</label>
                                    </div>)
                            }) : null
                    }
                    {
                        props.item.addons ?
                            props.item.addons.map((x, i) => {
                                if (i === 0) {
                                    return (
                                        <div className='adjacent' key={`qwer{i}`}>
                                            <div className='addon required-option modal-padding'>
                                                <div>Favorite Addons</div>
                                                <div className='required-option__italics'>Optional</div>
                                            </div>
                                            <div className="custom-control custom-checkbox custom-control-inline" key={`blahh${i}`}>
                                                <input type="checkbox" id={`cb_${i + 1}`} name="cb" className="custom-control-input" value={JSON.stringify(x)} onChange={
                                                    (e) => {
                                                        if (e.target.checked) {
                                                            setSelectedAddons([...selectedAddons, JSON.parse(e.target.value)])
                                                        } else {
                                                            setSelectedAddons(selectedAddons.filter(x => x.addonName !== JSON.parse(e.target.value).addonName))
                                                        }
                                                    }
                                                }></input>
                                                <label className="custom-control-label red" htmlFor={`cb_${i + 1}`}>{`Add ${x.addonName}`} + ${x.addonPrice}</label>
                                            </div>
                                        </div>
                                    )
                                } else return (
                                    <div className="custom-control custom-checkbox custom-control-inline" key={`blahh${i}`}>
                                        <input type="checkbox" id={`cb_${i + 1}`} name="cb" className="custom-control-input" value={JSON.stringify(x)} onChange={
                                            (e) => {
                                                if (e.target.checked) {
                                                    setSelectedAddons([...selectedAddons, JSON.parse(e.target.value)])
                                                } else {
                                                    setSelectedAddons(selectedAddons.filter(x => x.addonName !== JSON.parse(e.target.value).addonName))
                                                }
                                            }
                                        }></input>
                                        <label className="custom-control-label red" htmlFor={`cb_${i + 1}`}>{`Add ${x.addonName}`} + ${x.addonPrice}</label>
                                    </div>
                                )
                            }) : null
                    }

                    <div className='modal-padding additional-pref'>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">Additional preferences: (charges may apply)</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="1"></textarea>
                        </div>
                    </div>
                    <div className='modal-padding'>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect1">If Sold Out: </label>
                            <select className="form-control" id="exampleFormControlSelect1">
                                <option>Go with RestaurantApp recommendation</option>
                                <option>Refund this item</option>
                                <option>Contact me</option>
                                <option>Cancel the entire order</option>
                            </select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <UsesToolTip obj={{ id: "tool-tip-wrapper" }} isOpen={tooltipOpen} item={props.item} selectedAddons={selectedAddons}
                        selectedOption={selectedOption}
                        buttonDisabled={buttonDisabled}
                        toggle1={toggle1}
                        toggle={props.toggle}
                    />
                </ModalFooter>
            </Modal>
        </div>
    );
}
const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        addItem: (item) => dispatch(addItem(item))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Modal2);
