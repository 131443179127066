import React from 'react';
import './Cart-Item.scss'
import { Card } from 'react-bootstrap'
import { connect } from 'react-redux';
import { removeItem } from '../../reducer/action'

const CartItem = (props) => {


  return (
    <Card className='cart-item'>
      <Card.Header className='cart-header'><span className='cart-item-quantity'>{props.item.quantity}x </span>{props.item.name}</Card.Header>
      <Card.Body className='cart-item-body'>


        <div>
          <div>
            ${props.item.price}
          </div>

          {
            props.item.selectedOption.optionName && <div className='blah'>
              {props.item.selectedOption.optionName} {
                Number(props.item.selectedOption.optionPrice.replace(/[^0-9.-]+/g, "")) !== 0 && `+ $${Number(props.item.selectedOption.optionPrice.replace(/[^0-9.-]+/g, ""))}`
              }
            </div>
          }
          {
            Array.isArray(props.item.selectedAddons) && props.item.selectedAddons.length > 0 && <div>
              {props.item.selectedAddons.map((x, i) => {
                return (
                  <div key={`adon${i}`}>{`${x.addonName} +$${x.addonPrice}`}</div>
                )
              })}
            </div>
          }
        </div>

        <div className="bm-cross-button1" onClick={() => {
          props.removeItem(props.item)
        }}><span className='span-class'><span className="bm-cross1"></span><span className="bm-cross2"></span></span><button className='button-class1' tabIndex="0"></button></div>
      </Card.Body>
    </Card>


  )
}

const mapDispatchToProps = dispatch => {
  return {
    removeItem: item => dispatch(removeItem(item))
  }
}

export default connect(null, mapDispatchToProps)(CartItem);
