import React from 'react';

import { Card1 } from '../Card/Card.js';
import './Card-List.styles.css'

export const Cardlist = props => (
  <div className='row'>
  {props.category.list.map((card, index) => (
    <div className="col-sm-12 col-md-6 col-lg-4 mb-4" key={`b${index}`}>
      <Card1 key={card.name} card={card} open={props.open} close={props.close} itemHandler={props.itemHandler}/>
      </div>
    ))}
  </div>
);