import React, { useRef, useState, useEffect } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { addItem } from '../../reducer/action';


const UsesToolTip = (props) => {
    // const tooltipId = `${props.obj.id}`;

    const linkRef = useRef()
    const [ready, setReady] = useState(false)

    useEffect(() => {
        if (linkRef.current) {
            setReady(true)
        }
    }, [])
    return (
        <div ref={linkRef} target="_blank" className="tooltip-wrapper disabled" >
            <Button className="btn-round"
                color="danger" data-dismiss="modal" disabled={props.buttonDisabled} onClick={() => {
                    let item = { ...props.item };
                    let textArea = document.getElementById("exampleFormControlTextarea1").value;
                    let soldOutInput = document.getElementById('exampleFormControlSelect1').value;
                    item.instructions = textArea;
                    item.soldOut = soldOutInput;
                    item.selectedOption = props.selectedOption
                    item.selectedAddons = props.selectedAddons
                    props.addItem(item);
                    props.toggle()
                }}>Add to Cart - ${
                    (props.selectedAddons.reduce((accumulatedQuantity, item) => accumulatedQuantity + Number(item.addonPrice), 0) + Number(props.selectedOption.optionPrice) + Number(props.item.price)).toFixed(2)
                }</Button>
            {props.buttonDisabled && ready && <Tooltip placement="top" isOpen={props.isOpen} target={linkRef.current} toggle={props.toggle1}>
                Please select a required option!
                </Tooltip>}
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        addItem: (item) => dispatch(addItem(item))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UsesToolTip);